import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { Suspense, lazy, useState, useEffect } from 'react';
import Context from './Context';

import ScrollToTop from "./Components/ScrollToTop";
import axios from "axios";

const Header = lazy(() => import('./Components/Header'));
const Footer = lazy(() => import('./Components/Footer'));
const Cookies = lazy(() => import('./Components/Cookies'));

const Home = lazy(() => import('./Pages/Home'));
const Empresa = lazy(() => import('./Pages/Empresa'));
const Categorias = lazy(() => import('./Pages/Categorias'));
const Produtos = lazy(() => import('./Pages/Produtos'));
const Produto = lazy(() => import('./Pages/Produto'));
const Contato = lazy(() => import('./Pages/Contato'));
const NotFound = lazy(() => import('./Pages/NotFound'));


function App() {
  localStorage.setItem("apiUrl", "/sistema/");
  const [resumo, setResumo] = useState({});
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(false);
  const value = { resumo, setResumo, modal, setModal, activePage, setActivePage };
  useEffect(() => {
    axios.get(`${localStorage.getItem("apiUrl")}api/configuracoes`).then(response => {
      if (response.data.status !== 200) {
        setModal({
          titulo: "Ops!",
          texto: "Ocorreu um erro ao carregar o site, atualize a página e tente novamente!",
          botao: "Voltar ao site"
        });

        return false;
      }

      setResumo(response.data.success);
    });
  }, []);
  return (
    <HelmetProvider>
      <Context.Provider value={value}>
        <div id="modal" className={modal ? "active" : ""}>
          {modal && <div className="content">
            <h3>{modal.titulo}</h3>
            <p>{modal.texto}</p>
            <button type="button" onClick={() => { if (modal.onExit) { modal.onExit() } setModal(false) }}>{modal.botao}</button>
          </div>}
        </div>
        <Router>
          <Switch>
            <Route path="*">
              <Application />
            </Route>
          </Switch>
        </Router>
      </Context.Provider>
    </HelmetProvider>
  );
}

const Application = () => {
  let location = useLocation();
  return (
    <div className="App">
      <ScrollToTop />
      <Suspense fallback={<div></div>}>
        <Header />
        <TransitionGroup>
          <CSSTransition key={location.key} timeout={300} classNames="fade" mountOnEnter={true} unmountOnExit={true}>
            <Switch location={location}>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/empresa" exact>
                <Empresa />
              </Route>
              <Route path="/produtos" exact>
                <Categorias />
              </Route>
              <Route path="/categoria/:selected" exact>
                <Categorias />
              </Route>
              <Route path="/produtos/:slug" exact>
                <Produtos />
              </Route>
              <Route path="/produto/:id" exact>
                <Produto />
              </Route>
              <Route path="/contato" exact>
                <Contato />
              </Route>
              <Route component={NotFound} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
        <Cookies />
        <Footer />
      </Suspense>
    </div >
  )
}

export default App;
